// ** Redux **
import { Shopper } from '@/pages/dashboard/components/shopperDetails';
import { ShopperOptions } from '@/pages/projects/checkout-and-refund/components/shopperExperienceOptions';
import { config_type } from '@/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '../store';

export type Config = {
  id: string;
  name: string;
  project_id: string;
  environment: string;
  config_type: 'LOW_RISK' | 'HIGH_RISK' | 'MEDIUM_RISK';
  attribute: string;
  score: string;
  is_unselected: boolean;
  created_at: string;
  createdAt: string;
  updated_at: string;
};

export type FraudRiskTypeProps = {
  id: string;
  title: string;
  description: string;
  type?: string;
};

export type Project = {
  id: string;
  project_id: string;
  company_id?: string | null;
  api_key: string;
  api_key_detail?: {
    id: string;
    project_id: string;
    environment: string;
    api_key: string;
  };
  name: string;
  description: string;
  environment: string;
  status: string;
  published_at: Date;
  project_identifier: string;
  products:
    | 'CONSUMER_TRUST_INDEX'
    | 'CHECKOUT_AND_REFUND'
    | 'PAYMENT_PERSONALIZATION'
    | 'IDENTITY_AND_AUTH'
    | 'RETURNS_ORCHESTRATOR'
    | 'CHECKOUT_AND_REFUND_MANAGER'
    | 'PAYEE_TRUST_INDEX';
  fraud_risk_type_id: string;
  fraud_risk_type: FraudRiskTypeProps;
  created_at: string;
  updated_at: string;
  shopper_trust_index_scores?: Config[];
  trusted_shopper_scores?: Config[];
  low_risk_shopper_options: ShopperOptions;
  medium_risk_shopper_options: ShopperOptions;
  high_risk_shopper_options: ShopperOptions;
  low_risk_refund_details: {
    refund_execution: 'After' | 'Immediately';
    refund_trigger:
      | 'Approved for refund'
      | 'Received in warehouse'
      | 'Received by logistics';
    days_to_refund: number;
    refund_mode: 'Instant refund' | 'Refund after return';
  };
  medium_risk_refund_details: {
    refund_execution: 'After' | 'Immediately';
    refund_trigger: 'Approved for refund' | 'Received in warehouse';
    days_to_refund: number;
    refund_mode: 'Instant refund' | 'Refund after return';
  };
  high_risk_refund_details: {
    refund_execution: 'After' | 'Immediately';
    refund_trigger: 'Approved for refund' | 'Received in warehouse';
    days_to_refund: number;
    refund_mode: 'Instant refund' | 'Refund after return';
  };
  project?: Project; // Live Project
  projects?: Project; // Sandbox Project
};

export type Item = {
  id: string;
  config_type: string;
  score: number;
  recommended?: string;
  name: string;
  description: string;
  info: string;
  risk?: string;
};

type Scores = {
  id: string;
  name: string;
  project_id: string;
  environment: string;
  config_type: string;
  description: string;
  score: string;
  recommended: string;
};

export type ShopperTrustConfigScoreProps = {
  id: string;
  name: string;
  project_id: string;
  environment: string;
  config_type: string;
  description: string;
  score: string;
  recommended: string;
  fraud_risk_type_sub_score?: Scores[];
};

export type TrustedShopperScoreProps = {
  id: string;
  name: string;
  project_id: string;
  environment: string;
  config_type: config_type;
  description: string;
  score: string;
  recommended: string;
};

export type ProjectSliceType = {
  selectedProject: Project | null;
  riskLevelFilter: { label: string; value: string };
  shoppers: Shopper[] | null;
  activeProject: Project | null;
  projectType: string | null;
  shopperTrustConfig: { config: ShopperTrustConfigScoreProps[] } | null;
  shopperTrustTotal: number;
  trustedShopperConfig: TrustedShopperScoreProps[];
  trustedShopperScore: number;
  editedTrustedShoppersScoreForSubmission: {
    [key: string]: {
      score: number;
      recommended: number;
      config_type: config_type;
    };
  } | null;
  showPublishModal: boolean;
  showSaveModal: boolean;
  showPublishSuccessModal: boolean;
  showSaveSuccessModal: boolean;
  projectTitle: string;
  projects: Project[];
  loading: boolean;
  projectEnv: string;
  projectOverwriteWarning: boolean;
  projectUnpublishedWarning: boolean;
  promptDisabled: boolean;
};

const initialState: ProjectSliceType = {
  selectedProject: null,
  riskLevelFilter: { label: 'Recent', value: '' },
  shoppers: null,
  activeProject: null,
  projectType: null,
  shopperTrustConfig: null,
  shopperTrustTotal: 0,
  trustedShopperConfig: [],
  trustedShopperScore: 0,
  editedTrustedShoppersScoreForSubmission: null,
  showPublishModal: false,
  showSaveModal: false,
  showPublishSuccessModal: false,
  showSaveSuccessModal: false,
  projectTitle: '',
  loading: false,
  projects: [],
  projectEnv: 'SANDBOX',
  projectOverwriteWarning: false,
  projectUnpublishedWarning: true,
  promptDisabled: false,
};

const slice = createSlice({
  name: 'merchantProject',
  initialState,
  reducers: {
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
    setRiskLevelFilter: (state, { payload }) => {
      state.riskLevelFilter = payload;
    },
    setShoppers: (state, { payload }) => {
      state.shoppers = payload;
    },
    setActiveProject(state: ProjectSliceType, action: PayloadAction<Project | null>) {
      state.activeProject = action.payload;
    },
    setProjectType(state: ProjectSliceType, action: PayloadAction<string>) {
      state.projectType = action.payload;
    },
    setShopperTrustConfigScore(
      state: ProjectSliceType,
      action: PayloadAction<ShopperTrustConfigScoreProps[]>
    ) {
      state.shopperTrustConfig = { config: action.payload };
    },
    setShopperTrustTotal(
      state: ProjectSliceType,
      action: PayloadAction<{ [x: string]: number }>
    ) {
      state.shopperTrustTotal = Object.keys(action.payload).reduce(
        (accumulator, currentValue) => {
          return accumulator + action.payload[currentValue];
        },
        0
      );
    },
    setTrustedShopperConfigScore(
      state: ProjectSliceType,
      action: PayloadAction<TrustedShopperScoreProps[]>
    ) {
      state.trustedShopperConfig = action.payload;
      state.trustedShopperScore = action.payload?.[0]?.score
        ? parseInt(action.payload?.[0].score)
        : 80;
    },
    setTrustedShopperTotal: (
      state,
      action: PayloadAction<
        Record<string, { score: number; recommended: number; config_type: config_type }>
      >
    ) => {
      state.editedTrustedShoppersScoreForSubmission = action.payload;
      const t = Object.entries(action.payload).find((item) => {
        return item[1].config_type === 'LOW_RISK';
      });
      if (t) {
        state.trustedShopperScore = t[1].score;
      }
    },
    publishModalHandler: (state, { payload }) => {
      state.showPublishModal = payload;
    },
    saveModalHandler: (state, { payload }) => {
      state.showSaveModal = payload;
    },
    publishSuccessModalHandler: (state, { payload }) => {
      state.showPublishSuccessModal = payload;
    },
    saveSuccessModalHandler: (state, { payload }) => {
      state.showSaveSuccessModal = payload;
    },
    setProjectTitle: (state, { payload }) => {
      state.projectTitle = payload;
    },
    setProjectLoading: (
      state,
      {
        payload,
      }: {
        payload: boolean;
      }
    ) => {
      state.loading = payload;
    },
    setProjects: (
      state,
      {
        payload,
      }: {
        payload: Project[];
      }
    ) => {
      state.projects = payload;
    },
    setTrustedShopperScore: (state, { payload }: { payload: number }) => {
      state.trustedShopperScore = payload;
    },
    setProjectEnv: (state, { payload }) => {
      state.projectEnv = payload;
    },
    setProjectOverwriteWarning: (state, { payload }) => {
      state.projectOverwriteWarning = payload;
    },
    setProjectUnpublishedWarning: (state, { payload }) => {
      state.projectUnpublishedWarning = payload;
    },
    setPromptDisabled: (state, { payload }) => {
      state.promptDisabled = payload;
    },
    clearReduxData: (state) => {
      state.selectedProject = null;
      state.riskLevelFilter = { label: 'Recent', value: '' };
      state.shoppers = null;
      state.activeProject = null;
      state.projectType = null;
      state.shopperTrustConfig = null;
      state.shopperTrustTotal = 0;
      state.trustedShopperConfig = [];
      state.trustedShopperScore = 0;
      state.editedTrustedShoppersScoreForSubmission = null;
      state.showPublishModal = false;
      state.showSaveModal = false;
      state.showPublishSuccessModal = false;
      state.showSaveSuccessModal = false;
      state.projectTitle = '';
      state.loading = false;
      state.projects = [];
      state.projectEnv = 'SANDBOX';
      state.projectOverwriteWarning = false;
      state.projectUnpublishedWarning = true;
      state.promptDisabled = false;
    },
  },
});
export const { reducer } = slice;

export const {
  setSelectedProject,
  setRiskLevelFilter,
  setShoppers,
  setActiveProject,
  setProjectType,
  setShopperTrustConfigScore,
  setShopperTrustTotal,
  setTrustedShopperConfigScore,
  setTrustedShopperTotal,
  publishModalHandler,
  saveModalHandler,
  publishSuccessModalHandler,
  saveSuccessModalHandler,
  setProjectTitle,
  setProjectLoading,
  setProjects,
  setTrustedShopperScore,
  setProjectEnv,
  setProjectOverwriteWarning,
  setProjectUnpublishedWarning,
  setPromptDisabled,
  clearReduxData,
} = slice.actions;

export const getActiveProject = (state: IRootState) => state.project.activeProject;

const CONSUMER_TRUST_INDEX = 'CONSUMER_TRUST_INDEX';
const PAYEE_TRUST_INDEX = 'PAYEE_TRUST_INDEX';
const CHECKOUT_AND_REFUND = 'CHECKOUT_AND_REFUND';
const RETURNS_ORCHESTRATOR = 'RETURNS_ORCHESTRATOR';
const IDENTITY_AND_AUTH = 'IDENTITY_AND_AUTH';
const PAYMENT_PERSONALIZATION = 'PAYMENT_PERSONALIZATION';

export type config_type = 'LOW_RISK' | 'MEDIUM_RISK' | 'HIGH_RISK';

export const ORGANIZATION_TYPES = Object.freeze({
  NETWORK_MASTER: 'NETWORK_MASTER',
  PARTNER: 'PARTNER',
  NODE: 'NODE',
});

export const ROLES = Object.freeze({
  ADMIN: 'Admin',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
});

export const TransactionStatus = Object.freeze({
  Successful: 'Successful',
  Pending: 'Pending',
  Declined: 'Declined',
});

export const FraudRiskTypeStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  COMING_SOON: 'COMING_SOON',
});

export const PUBLIC_NAVIGATION: { [key: string]: string } = Object.freeze({
  login: '/login',
  verifySecureCode: '/verify-securecode',
  signUp: '/signup/:code',
  confirmEmail: '/confirm-email',
  signUpSuccess: '/signup-success',
  enrollment: '/enrollment',
  useCases: 'use-case',
  resetPassword: '/reset-password',
  resetEmailVerify: '/verify-reset-email',
  setNewPassword: '/set-new-password/:token',
  resetPasswordSuccess: '/reset-password-success',
  readmeDocs: '/readme-docs',
  projectInvite: '/project-invite/:token',
});

export const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const FeaturesEnum = {
  Organization: 'Organisation',
  Users: 'Users',
  Roles: 'Roles',
  Projects: 'Projects',
  Dashboard: 'Dashboard',
};

export const rolePermissionEnum = {
  [FeaturesEnum.Organization]: {
    ViewPartner: 'View Partner',
    UpdatePartner: 'Update Partner',
    DeletePartner: 'Delete Partner',
    CreatePartner: 'Create Partner',
    CreateNode: 'Create Node',
    ViewNode: 'View Node',
    UpdateNode: 'Update Node',
    DeleteNode: 'Delete Node',
  },
  [FeaturesEnum.Users]: {
    CreateTeam: 'Create Team',
    ViewTeam: 'View Team',
    UpdateTeam: 'Update Team',
    DeleteTeam: 'Delete Team',
    CreateUser: 'Create User',
    ViewUser: 'View User',
    UpdateUser: 'Update User',
    DeleteUser: 'Delete User',
  },
  [FeaturesEnum.Projects]: {
    CreateProject: 'Create Project',
    ViewProject: 'View Project',
    UpdateProject: 'Update Project',
    DeleteProject: 'Delete Project',
  },
  [FeaturesEnum.Dashboard]: {
    ViewOrganizations: 'View organizations',
    ViewListOfConsumers: 'View list of consumers',
    ViewDashboard: 'View Dashboard',
  },
  [FeaturesEnum.Roles]: {
    CreateRole: 'Create Role',
    UpdateRole: 'Update Role',
    ViewRole: 'View Role',
    DeleteRole: 'Delete Role',
  },
};

export const PRIVATE_NAVIGATION = Object.freeze({
  consumerTrustGuide: {
    activeSidebar: -1,
    path: '/consumer-trust-index-guide',
    feature: true,
    permission: true,
    organization: true,
  },
  auth: {
    signup: {
      activeSidebar: 2,
      path: '/signup/:code',
      feature: true,
      permission: true,
      organization: true,
    },
    signupsuccess: {
      activeSidebar: 2,
      path: '/signup-success',
      feature: true,
      permission: true,
      organization: true,
    },
    projectinvite: {
      activeSidebar: 2,
      path: '/project-invite/:token',
      feature: true,
      permission: true,
      organization: true,
    },
  },
  useCase: {
    list: {
      activeSidebar: 2,
      path: '/use-case',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    product: {
      activeSidebar: 2,
      path: '/use-case/:product',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
  home: {
    activeSidebar: 1,
    path: '/home',
    feature: true,
    permission: true,
    organization: true,
  },
  users: {
    list: {
      activeSidebar: 3,
      path: '/users',
      feature: FeaturesEnum.Users,
      permission: rolePermissionEnum[FeaturesEnum.Users].ViewUser,
      organization: true,
    },
    view: {
      activeSidebar: 3,
      path: '/users/:id',
      feature: FeaturesEnum.Users,
      permission: rolePermissionEnum[FeaturesEnum.Users].ViewUser,
      organization: true,
    },
  },
  teams: {
    list: {
      activeSidebar: 3,
      path: '/teams',
      feature: true,
      permission: true,
      organization: true,
    },
    view: {
      activeSidebar: 3,
      path: '/teams/:id',
      feature: true,
      permission: true,
      organization: true,
    },
  },
  groups: {
    list: {
      activeSidebar: 3,
      path: '/groups',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    view: {
      activeSidebar: 3,
      path: '/groups:id',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    roles: {
      activeSidebar: 3,
      path: '/groups:id/roles',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
  projects: {
    list: {
      activeSidebar: 2,
      path: '/projects',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].ViewProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    add: {
      activeSidebar: 0,
      path: '/projects/new',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    edit: {
      activeSidebar: 0,
      path: '/projects/edit',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].UpdateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    view: {
      activeSidebar: 0,
      path: '/projects/view',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].ViewProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    invite: {
      activeSidebar: 0,
      path: '/projects/invite',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    setting: {
      path: '/projects/settings',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].UpdateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    sti: {
      getStarted: {
        activeSidebar: 0,
        path: '/projects/sti/get-started',
        feature: FeaturesEnum.Projects,
        permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
        organization: ORGANIZATION_TYPES.NODE,
      },
    },
    cr: {
      getStarted: {
        activeSidebar: 2,
        path: '/projects/cr/get-started',
        feature: FeaturesEnum.Projects,
        permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
        organization: ORGANIZATION_TYPES.NODE,
      },
    },
    configure: {
      activeSidebar: 0,
      path: '/projects/configure',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    manage: {
      activeSidebar: 2,
      path: '/projects/manage',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].ViewProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
  setupPayment: {
    list: {
      activeSidebar: 2,
      path: '/setup-payment',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    bankLogin: {
      activeSidebar: 2,
      path: '/setup-payment/bank-login',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    bankConsent: {
      activeSidebar: 2,
      path: '/setup-payment/bank-consent',
      organization: ORGANIZATION_TYPES.NODE,
      feature: true,
      permission: true,
    },
    setupSuccess: {
      activeSidebar: 2,
      path: '/setup-payment/setup-success',
      organization: ORGANIZATION_TYPES.NODE,
      feature: true,
      permission: true,
    },
    setupDeclined: {
      activeSidebar: 2,
      path: '/setup-payment/setup-declined',
      organization: ORGANIZATION_TYPES.NODE,
      feature: true,
      permission: true,
    },
  },
  organization: {
    list: {
      activeSidebar: 4,
      path: '/network',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].ViewPartner},${rolePermissionEnum[FeaturesEnum.Organization].ViewNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
    add: {
      activeSidebar: 4,
      path: '/organization/create',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].CreatePartner},${rolePermissionEnum[FeaturesEnum.Organization].CreateNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
    setup: {
      activeSidebar: -1,
      path: '/organization/setup',
      feature: true,
      permission: true,
      organization: true,
    },
    inviteAdmin: {
      activeSidebar: 4,
      path: '/organization/invite-admin',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].CreatePartner},${rolePermissionEnum[FeaturesEnum.Organization].CreateNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
    invite: {
      activeSidebar: 4,
      path: '/organization/invite',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].CreatePartner},${rolePermissionEnum[FeaturesEnum.Organization].CreateNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
  },
  contactSupport: {
    activeSidebar: 7,
    path: '/contact-support',
    feature: true,
    permission: true,
    organization: true,
  },
  onboarding: {
    ConsumerTrustNetwork: {
      activeSidebar: 0,
      path: '/onboard-into-consumer-trust-network-sandbox',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    uploadHistoricalData: {
      activeSidebar: 0,
      path: '/upload-historical-data',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    ctiConfirmation: {
      activeSidebar: 0,
      path: '/cti-confirmation',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
});

export {
  CHECKOUT_AND_REFUND,
  CONSUMER_TRUST_INDEX,
  IDENTITY_AND_AUTH,
  PAYEE_TRUST_INDEX,
  PAYMENT_PERSONALIZATION,
  RETURNS_ORCHESTRATOR,
};

export const DropDownOptions = Object.freeze({
  teams: [
    {
      label: 'General',
      value: 'General',
    },
    {
      label: 'Management',
      value: 'Management',
    },
  ],
  roles: [
    {
      label: 'Admin',
      value: 'Admin',
      description: 'Can fully configure and edit application',
    },
    {
      label: 'Editor',
      value: 'Editor',
      description: 'Can edit but cannot perform admin tasks',
    },
    {
      label: 'Viewer',
      value: 'Viewer',
      description: 'Can only view',
    },
  ],
  listBoxOptions: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: '/api/networksearch',
      value: '/api/networksearch',
    },
    {
      label: '/api/projects',
      value: '/api/projects',
    },
    {
      label: '/api/fraudprofiles',
      value: '/api/fraudprofiles',
    },
    {
      label: '/api/trudocs',
      value: '/api/trudocs',
    },
    {
      label: '/api/shoppertrustindex',
      value: '/api/shoppertrustindex',
    },
  ],

  DateRangeOptions: [
    {
      value: 30,
      label: 'Last 30 Days',
    },
    {
      value: 20,
      label: 'Last 20 Days',
    },
    {
      value: 15,
      label: 'Last 15 Days',
    },
    {
      value: 10,
      label: 'Last 10 Days',
    },
    {
      label: 'Last 5 Days',
      value: 5,
    },
  ],
  AllRecentOptions: [
    { value: 'all', label: 'All' },
    { value: 'recent', label: 'Recent' },
  ],
});

export const tabOptions = ['Organisation', 'Users', 'Projects', 'Dashboard', 'Roles'];

export const roleAndPermissionData: any = {
  [ROLES.ADMIN]: {
    organization: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Node',
        description: 'Setup a new node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'View Node',
        description: 'Browse through a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Node',
        description: 'Make changes to a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Node',
        description: 'Permanently remove a node organisation.',
        hasPermission: 'true',
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: 'true',
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: 'true',
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: 'true',
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: 'true',
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: 'true',
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: 'true',
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: 'true',
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: 'true',
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
  [ROLES.EDITOR]: {
    organization: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Node',
        description: 'Setup a new node organisation.',
        hasPermission: false,
      },
      {
        title: 'View Node',
        description: 'Browse through a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Node',
        description: 'Make changes to a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Node',
        description: 'Permanently remove a node organisation.',
        hasPermission: false,
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: 'true',
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: 'true',
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: 'true',
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: 'true',
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: 'true',
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: 'true',
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: 'true',
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: 'true',
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
  [ROLES.VIEWER]: {
    organization: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Node',
        description: 'Setup a new node organisation.',
        hasPermission: false,
      },
      {
        title: 'View Node',
        description: 'Browse through a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Node',
        description: 'Make changes to a node organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Node',
        description: 'Permanently remove a node organisation.',
        hasPermission: false,
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: false,
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: false,
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: false,
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: false,
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: false,
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: false,
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: false,
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: false,
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: false,
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
};

export const ShopperConfigSequence = [
  'IDENTITY',
  'BANKING_HISTORY',
  'OPEN_BANKING',
  'HISTORICAL_DATA',
  'NETWORK_INTELLIGENCE',
  'THIRD_PARTY_DATA',
  'CHARGEBACKS',
  'RETURNS',
  'CLAIMS_OF_ITEMS_NOT_RECEIVED',
  'BNPL',
  'AUTHORISED_PUSH_PAYMENTS',
  'MULLING',
  'PROMOTION_BONUS_AND_LOYALTY_ABUSE',
  'AFFORDABILITY_AND_PAYMENTS',
];

export const webURL = `${window.location.protocol}//${window.location.host}`;

export const ReasonCodesList = [
  {
    trust_dimension: 'Identity',
    trust_dimension_description:
      'Verification of consumer identity along a holistic matrix of verified identity attributes.',
    trust_dimension_score: 5.5,
    trust_dimension_reason_code: 'ID04',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Consumer has not provided bank verification & has consistent identity across network, and has indicators of syndicate activities.',
    feature_sets: [
      {
        name: 'Network-based identity validation',
        description:
          'Verification of consumer identity through the consumer’s bank and across nodes on the network.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID10',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Identity could not be verified using bank data or merchant data',
            score: 3,
          },
          {
            notable: true,
            code: 'ID103',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Identity verified by a single merchant or issuer, minimal cross-referencing',
            score: 1,
          },
          {
            notable: true,
            code: 'IB04',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Verified identity by single merchant/issuer and bank authentication, lacks multiple commerce touchpoints.',
            score: 1,
          },
          {
            notable: true,
            code: 'IN03',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Customer name matches bank records on surname only',
            score: 1,
          },
        ],
      },
      {
        name: 'Digital identity - email',
        description: 'Evaluation of email activity and usage patterns for credibility.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID203',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Email account was created recently, indicating possible fraud.',
            score: 1,
          },
          {
            notable: true,
            code: 'ID303',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer’s email does not match phone and address records across bank/merchant databases.',
            score: 0,
          },
          {
            notable: true,
            code: 'IE05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Email and phone number & address are linked but show inconsistent history (e.g.,recent change).',
            score: 0,
          },
        ],
      },
      {
        name: 'Digital identity - Device Fingerprinting',
        description:
          'Assessment of digital identity fingerprinting consistency, including device and browser fingerprinting associations with fraudulent activity, and behavioural anomalies across multiple platforms.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID403',
            risk_level: 'HIGH_RISK',
            description:
              'The same device is used for multiple different customer accounts.',
            score: 1,
          },
          {
            notable: true,
            code: 'ID503',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer is using a VPN, proxy, or anonymizer service, masking true location.',
            score: 0,
          },
          {
            notable: false,
            code: 'ID01',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The device fingerprint is inconsistent with historical transactions and associated with a new or unrecognised device',
            score: 0,
          },
        ],
      },
      {
        name: 'Digital identity - Browser Fingerprinting',
        description:
          'Assessment of digital identity browser fingerprinting consistency, detects when malicious tools commonly used to spoof devices, such as emulators, are being used.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID603',
            risk_level: 'HIGH_RISK',
            description:
              'Browser fingerprint matches multiple devices or is used inconsistently.',
            score: 0,
          },
          {
            notable: true,
            code: 'IBR03',
            risk_level: 'HIGH_RISK',
            description:
              'Inconsistent fingerprinting data across sessions (e.g., same user but different devices or environments).',
            score: 0,
          },
        ],
      },
      {
        name: 'Digital identity - Geolocation',
        description:
          'Assessment of location consistency, shared geolocation patterns, and unusual access points across networks.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID703',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'A single delivery address is used for transactions across multiple identities.',
            score: 0,
          },
          {
            notable: true,
            code: 'ID803',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              "Consumer's provided address is a P.O. Box instead of a residence.",
            score: 1,
          },
        ],
      },
      {
        name: 'Payment methods',
        description:
          'Evaluation of payment method usage, account links, and inconsistencies in payment and account details to identify potential risks.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID903',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'A single payment card is used across more accounts than the average number of people per household in the area, suggesting syndicate activities.',
            score: 1,
          },
          {
            notable: true,
            code: 'ID402',
            risk_level: 'HIGH_RISK',
            description:
              ' IBANs have received a positive name and account matches are linked to reported cases of fraud.',
            score: 1,
          },
        ],
      },
      {
        name: 'Synthetic Identity',
        description:
          'Evaluation of the likelihood the consumer profile is a synthetic identity',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID901',
            risk_level: 'TRUSTED',
            description:
              'Email, phone, payment method, and address are associated with a single identity.',
            score: 1,
          },
        ],
      },
      {
        name: 'Syndicate activity',
        description:
          'Evaluation of likelihood the consumer profile is operating as part of a syndicate or organized crime',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'ID903',
            risk_level: 'TRUSTED',
            description:
              'Account activity and interactions appear typical and independent.',
            score: 1,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Chargebacks',
    trust_dimension_description:
      "Assessment of a consumer's chargeback behaviour across the ecosystem, including frequency and fraud.",
    trust_dimension_score: 5,
    trust_dimension_reason_code: 'CB07',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Consumer exhibits a medium to high frequency of chargebacks (>0.3%) across multiple payment methods and merchants, with chargeback claims generally honoured.',
    feature_sets: [
      {
        name: 'Chargeback history',
        description:
          'Analysis of chargeback rates and reasons to identify fraud trends and customer behavior patterns.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'CB102',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer has high chargeback rate (> 0.3%) across multiple payment methods and merchants',
            score: 1,
          },
          {
            notable: true,
            code: 'CB201',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Chargeback claims are generally granted',
            score: 1,
          },
          {
            notable: true,
            code: 'CB303',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer chargeback claims distributed across multiple merchants',
            score: 0,
          },
          {
            notable: true,
            code: 'CB403',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer chargebacks predominantly on digital goods, luxury items, or high-value transactions.',
            score: 2,
          },
          {
            notable: true,
            code: 'CB503',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer leverages inconsistent identities across chargebacks and transactions (i.e., payment methods, device, name)',
            score: 0,
          },
          {
            notable: true,
            code: 'CB603',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer activity indicative of syndicate activity and muling (eCommerce and/financial)',
            score: 0,
          },
          {
            notable: true,
            code: 'CB703',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer chargebacks predominantly related to transactions without 3D secure',
            score: 1,
          },
          {
            code: 'CB803',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The account has no/limited prior purchase history, customer reviews, or interactions but initiates a high-value transaction, which is later disputed through a chargeback.',
            score: 0,
          },
          {
            notable: true,
            code: 'CB903',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The customer files a chargeback and then repurchases the same or similar item within a short period, possibly using a different payment method or account.',
            score: 0,
          },
          {
            notable: true,
            code: 'CB113',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The customer’s spending pattern fluctuates unnaturally after chargebacks—either making sudden large purchases or stopping activity completely.',
            score: 0,
          },
          {
            notable: true,
            code: 'CB123',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The account has limited prior purchase history or interactions but initiates a high-value transaction, which is later disputed through a chargeback.',
            score: 0,
          },
        ],
      },
      {
        name: 'Dispute and claims history',
        description:
          'Analysis of dispute and claims handling data to identify fraud trends and customer behavior patterns.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'CB102',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer activity consistent with genuine personal usage; no signs of syndicate involvement or financial muling (ecommerce and/or financial)',
            score: 1,
          },
          {
            notable: true,
            code: 'CB201',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer claims predominantly on digital goods, luxury items, or high-value transactions.',
            score: 1,
          },
          {
            notable: true,
            code: 'CB303',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer claims concentrated with a specific merchant, could be legitimate merchant issues',
            score: 0,
          },
          {
            notable: true,
            code: 'CB403',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer chargebacks predominantly on digital goods, luxury items, or high-value transactions.',
            score: 2,
          },
          {
            notable: true,
            code: 'CB503',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer leverages inconsistent identities across chargebacks and transactions (i.e., payment methods, device, name)',
            score: 0,
          },
          {
            notable: true,
            code: 'CB603',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer activity indicative of syndicate activity and muling (eCommerce and/financial)',
            score: 0,
          },
          {
            notable: true,
            code: 'CB703',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Consumer chargebacks predominantly related to transactions without 3D secure',
            score: 1,
          },
          {
            code: 'CB803',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The account has no/limited prior purchase history, customer reviews, or interactions but initiates a high-value transaction, which is later disputed through a chargeback.',
            score: 0,
          },
          {
            notable: true,
            code: 'CB903',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The customer files a chargeback and then repurchases the same or similar item within a short period, possibly using a different payment method or account.',
            score: 0,
          },
          {
            notable: true,
            code: 'CB113',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The customer’s spending pattern fluctuates unnaturally after chargebacks—either making sudden large purchases or stopping activity completely.',
            score: 0,
          },
          {
            notable: true,
            code: 'CB123',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The account has limited prior purchase history or interactions but initiates a high-value transaction, which is later disputed through a chargeback.',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Returns',
    trust_dimension_description:
      "Evaluation of a consumer's returns and refunds behaviour across the ecosystem, including frequency and fraud.",
    trust_dimension_score: 5,
    trust_dimension_reason_code: 'R01',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Consumer has medium to high return rate (< x%) across multiple payment methods and merchants, and refunds are generally honoured.',
    feature_sets: [
      {
        name: 'Refund History',
        description: 'Evaluation of refund outcomes to assess risk tendencies.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BR03',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'The customer has a high refund rate across different merchants',
            score: 0,
          },
          {
            notable: true,
            code: 'BR05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Moderate return rate with medium fraud indicators',
            score: 0,
          },
          {
            notable: true,
            code: 'BR8',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Moderate refund frequency with variable durations between refund requests',
            score: 0,
          },
          {
            notable: true,
            code: 'BR11',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Moderate average refund value from the customer',
            score: 0,
          },
        ],
      },
      {
        name: 'Return History',
        description: 'Evaluation of return outcomes to assess risk tendencies.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'NR03',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'The customer consistently uses the same refund reasons and timing across different merchants, indicating a repeated fraud pattern',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Claims of Items-not-received',
    trust_dimension_description:
      "Evaluation of a consumer's history with claimed or factual delivery issues, and instances of recurring Item Not Received (INR) claims.",
    trust_dimension_score: 5,
    trust_dimension_reason_code: 'SHIP1',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Consumer has medium return rate across multiple payment methods and merchants, and refunds are. generally honoured.',
    feature_sets: [
      {
        name: 'Claim History',
        description:
          'Evaluation of shipping and delivery patterns to identify risks associated with delivery issues',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'SHIP05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Late Delivery vs. Expected Time—significant delay between expected and actual delivery time, increasing INR (Item Not Received) claims.',
            score: 0,
          },
        ],
      },
      {
        name: 'Shipping and delivery',
        description:
          'Evaluation of shipping and delivery patterns to identify risks associated with delivery issues',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'SHIP05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Late Delivery vs. Expected Time—significant delay between expected and actual delivery time, increasing INR (Item Not Received) claims.',
            score: 0,
          },
        ],
      },
      {
        name: 'Historical Fraud Indicators',
        description:
          'Evaluation of shipping and delivery patterns to identify risks associated with delivery issues',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'SHIP05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Late Delivery vs. Expected Time—significant delay between expected and actual delivery time, increasing INR (Item Not Received) claims.',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'BNPL',
    trust_dimension_description:
      "Assessment of a consumer's patterns of BNPL coverage, defaults, and ghosting across the ecosystem.",
    trust_dimension_score: 5,
    trust_dimension_reason_code: 'BNPL1',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      ' Consumer has consistent BNPL orders with timely payments, along with stable income and regular use of the same trusted identity.',
    feature_sets: [
      {
        name: 'BNPL affordability and exposure',
        description:
          'BNPL affordability and exposure, synthetic data in application, consumer ghosting and bust out behavior',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BNPL01',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'No late payments for BNPL in open banking data.',
            score: 0,
          },
          {
            notable: true,
            code: 'BNPL05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Consumer has multiple loans but maintains timely payments.',
            score: 0,
          },
        ],
      },
      {
        name: 'Synthetic data in application',
        description:
          'BNPL affordability and exposure, synthetic data in application, consumer ghosting and bust out behavior',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BNPL01',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'No late payments for BNPL in open banking data.',
            score: 0,
          },
          {
            notable: true,
            code: 'BNPL05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Consumer has multiple loans but maintains timely payments.',
            score: 0,
          },
        ],
      },
      {
        name: 'Refund and Return Behaviors',
        description:
          'BNPL affordability and exposure, synthetic data in application, consumer ghosting and bust out behavior',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BNPL01',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'No late payments for BNPL in open banking data.',
            score: 0,
          },
          {
            notable: true,
            code: 'BNPL05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Consumer has multiple loans but maintains timely payments.',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Authorised Push Payments',
    trust_dimension_description:
      "Evaluation of the consumer's association with claims of scamming or APP fraud",
    trust_dimension_score: 5,
    trust_dimension_reason_code: 'APP1',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Consumer maintains a consistent list of recipients and payees over time.',
    feature_sets: [
      {
        name: 'Payer risk for APP fraud',
        description: 'Payer cash flow and payments',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'APP02',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'High-volume peer-to-peer transfers without clear transactional purpose, indicating potential fraud or mule account activity.',
            score: 0,
          },
        ],
      },
      {
        name: 'Payee risk for APP fraud',
        description: 'Payee cash flow and payments',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'APP02',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'High-volume peer-to-peer transfers without clear transactional purpose, indicating potential fraud or mule account activity.',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Mulling',
    trust_dimension_description:
      'Evaluation of purchasing and transaction patterns to identify risks associated with financial and ecommerce muling.',
    trust_dimension_score: 5,
    trust_dimension_reason_code: '',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Small consistent payments followed by occasional withdrawals, combined with product orders shipped to multiple pickup points.',
    feature_sets: [
      {
        name: 'E-commerce Mulling',
        description:
          'Evaluation of purchasing and shipping patterns to identify risks associated with fraudulent order fulfillment and reshipping activities.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'EM102',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Orders from multiple accounts with the same payment methods, showing delayed fund movements.',
            score: 0,
          },
        ],
      },
      {
        name: 'Financial Mulling',
        description:
          'Evaluation of transaction flow patterns to identify risks associated with suspicious fund transfers and potential money laundering activities.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'EM204',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Small consistent payments followed by occasional withdrawals, combined with product orders shipped to multiple pickup points.',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Promotion, Bonus & Loyalty Abuse',
    trust_dimension_description:
      'Evaluation of purchasing and transaction patterns to identify risks associated with financial and ecommerce muling.',
    trust_dimension_score: 5,
    trust_dimension_reason_code: '',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Small consistent payments followed by occasional withdrawals, combined with product orders shipped to multiple pickup points.',
    feature_sets: [
      {
        name: 'Account Creation and Identity',
        description:
          'Monitoring for fraudulent account creation using fake or duplicate identities to exploit policies.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'PB101',
            risk_level: 'TRUSTED',
            description:
              'Consumer has verified and long-standing profile with no sign of funnel accounts.',
            score: 0,
          },
        ],
      },
      {
        name: 'Transaction Behavior Risk Features',
        description:
          'Analyzing transaction patterns to detect suspicious behaviors, such as rapid bonus redemptions, abnormal purchases, or frequent small transactions.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'PB203',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Points earned from transactions that were later reversed, canceled, or flagged as suspicious..',
            score: 0,
          },
        ],
      },
      {
        name: 'Loyalty Program Abuse',
        description:
          'Identifying misuse of loyalty programs, including unauthorized point accumulation, multiple redemptions through fake or duplicate accounts.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'PB401',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'User claiming bonuses across multiple merchants using similar credentials.',
            score: 0,
          },
        ],
      },
    ],
  },
  {
    trust_dimension: 'Affordability and Payments',
    trust_dimension_description:
      "Assessment of a consumer's propensity towards first-party fraud based on affordability, payments and banking behaviours.",
    trust_dimension_score: 5.5,
    trust_dimension_reason_code: 'BP1',
    trust_dimension_risk_level: 'MEDIUM_TRUSTED',
    trust_dimension_score_range_HighRisk: '0-3',
    trust_dimension_score_range_MediumRisk: '3-6',
    trust_dimension_score_range_Trusted: '6-8',
    trust_dimension_name:
      'Consumer has stable income, with a notable decrease/increase in shopping behavior compared to the previous Customer Trust Index ',
    feature_sets: [
      {
        name: 'Affordability',
        description:
          'Indicators of purchasing power that may signal potential risk for all fraud types',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BAF01',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'The customer has stable income over the past 3 months',
            score: 0,
          },
        ],
      },
      {
        name: 'Order History',
        description:
          "Overview of transaction patterns within the consumer's purchase history.",
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BOH03',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Significant average order value compared to account balance, increasing the risk of transaction authenticity issues.',
            score: 0,
          },
          {
            notable: true,
            code: 'BOH04',
            risk_level: 'MEDIUM_HIGH_RISK',
            description: 'Transactions occur regularly during standard hours of the day.',
            score: 1,
          },
          {
            notable: true,
            code: 'BOH06',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Transactional behavior aligns with account history and expected patterns.',
            score: 0,
          },
        ],
      },
      {
        name: 'Bank Activity',
        description:
          'Analysis of financial transaction behaviours for signs of unusual activity.',
        feature_set_risk_level: 'MEDIUM_TRUSTED',
        feature_set_score: 5,
        feature_set_score_range_HighRisk: '0-2',
        feature_set_score_range_MediumRisk: '2-5',
        feature_set_score_range_Trusted: '5-8',
        reason_codes: [
          {
            notable: true,
            code: 'BA02',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Incoming flows are moderately stable with occasional fluctuations',
            score: 2,
          },
          {
            notable: true,
            code: 'BA05',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Outgoing flows are moderately stable with occasional fluctuations',
            score: 3,
          },
          {
            notable: false,
            code: 'BA07',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'Funds received from multiple sources, some irregular activity detected.',
            score: 0,
          },
          {
            notable: true,
            code: 'BP03',
            risk_level: 'MEDIUM_HIGH_RISK',
            description:
              'No transactions are recorded involving low-traceability accounts',
            score: 3,
          },
        ],
      },
    ],
  },
];

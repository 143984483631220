import SiteLoader from '@/components/Loaders/SiteLoader';
import SideBar from '@/components/sidebar';
import TrustNetworkPage from '../onboarding/trustNetworkPage';

type Props = {
  children: JSX.Element;
  sidebarId?: number;
  path?: string;
};

const RequiresAuth = (props: Props) => {
  const { children, sidebarId } = props;
  return (
    <div className="flex w-full h-screen overflow-auto">
      {sidebarId && sidebarId > -1 ? <SideBar activeNav={sidebarId ?? 0} /> : <></>}
      {children}
      <TrustNetworkPage />
      <SiteLoader />
    </div>
  );
};

export default RequiresAuth;
